<script setup lang="ts">
import { capitalize, get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { VButton, VTable } from '@/modules/shared/components'
import { Option } from '@/modules/shared/utils/form'
import { useDocumentStore } from '../stores/document-store'
import { useTemplateStore } from '@/modules/signing/stores/template-store'
import { format } from '@/modules/shared/utils/v-table'
import { rails_url } from '@/modules/shared/utils/rails'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

const { t } = useI18n()

const props = defineProps<{
  investor_type: 'spv' | 'fund' | 'gp' | 'individual' | 'funding-entity' | 'group' | 'all'
  investor_id: string | number
  filterOptions?: Option[]
  selected_keys?: string[]
  skeleton?: boolean
}>()

const internal_skeleton = ref(true)
const authStore = useAuthStore()
const documentStore = useDocumentStore()
const templateStore = useTemplateStore()

const skeleton = computed(() => props.skeleton || internal_skeleton.value)
const filterOptions = computed(() => props.filterOptions || [])
const selected_keys = computed(() => props.selected_keys || [])
function can(block, action) {
  switch (block) {
    case 'template':
      if (['request-signature', 'build-template', 'view', 'action-buttons'].includes(action)) {
        return !['individual', 'funding-entity'].includes(props.investor_type) && authStore.is_site_or_group_admin
      }
      break
    case 'document':
      if (['void'].includes(action)) return authStore.is_site_or_group_admin
      break
    case 'investor':
      if (['view'].includes(action)) return authStore.is_site_or_group_admin
      break
    default:
      break
  }

  return false
}

const requestSignatureUrl = computed(() => {
  let url = null
  switch (props.investor_type) {
    case 'spv':
    case 'fund':
    case 'gp':
      url = { name: 'investing.entity.document.new' }
      break
    case 'group':
      url = { name: 'investing.document.new' }
      break

    default:
      break
  }

  return url
})
const buildTemplateUrl = computed(() => {
  let url = null
  switch (props.investor_type) {
    case 'spv':
    case 'fund':
    case 'gp':
      url = {
        name: 'signing-entity-build-template',
        params: { templateable_type: props.investor_type, templateable_id: props.investor_id },
      }
      break
    case 'group':
      url = { name: 'signing-group-build-template' }
      break

    default:
      break
  }

  return url
})

// TEMPLATES
const templates = computed(() =>
  // hellosign_template_cache causes error in deep search of v-table so we set this to null temporarily
  templateStore.templates.map((template) => {
    return { ...template, hellosign_template_cache: null }
  }),
)
const removeTemplate = async (item) => {
  await templateStore.removeTemplate(item.id)
  await templateStore.fetchTemplates(props.investor_type, props.investor_id)
}
const generateUseTemplateUrl = (item) => {
  const template = templateStore.template_items.get(item.id)
  let url = null
  switch (props.investor_type) {
    case 'spv':
    case 'fund':
    case 'gp':
      url = { name: 'investing.entity.document.new', query: { hellosign_template_id: template.hellosign_template_id } }
      break
    case 'group':
      url = { name: 'investing.document.new', query: { hellosign_template_id: template.hellosign_template_id } }
      break

    default:
      break
  }

  return url
}

// DOCUMENTS
const selectedDocumentType = ref('in-progress')
const selectDocumentType = (type: string) => {
  selectedDocumentType.value = type
}
const currentDocuments = computed(() =>
  documentStore.documents
    .filter((document) => {
      if (selectedDocumentType.value === 'in-progress') {
        return document.status === 'awaiting'
      }
      if (selectedDocumentType.value === 'completed') {
        return document.status === 'completed'
      }
      if (selectedDocumentType.value === 'voided') {
        return document.status === 'voided'
      }
    })
    .filter((document) => {
      if (selected_keys.value.length === filterOptions.value.length) return true
      return document.signatures.some((signature) => selected_keys.value.includes(signature.investor._cid))
    }),
)
const document_count = computed(() => {
  let in_progress = 0
  let completed = 0
  let voided = 0

  documentStore.documents?.forEach((document) => {
    if (document.status === 'awaiting') in_progress++
    if (document.status === 'completed') completed++
    if (document.status === 'voided') voided++
  })

  return { in_progress, completed, voided }
})
const getDocumentStatus = (item) => {
  const document = documentStore.items.get(item.id)
  if (document.status === 'voided') return `Voided ${format(document.voided_at, 'date')}`
  const no_of_signed = document.signatures.filter((signature) => signature.status === 'Signed').length

  return `${no_of_signed} / ${document.signatures.length} signatures received`
}
const voidDocument = async (item) => {
  await documentStore.voidDocument(item.id)
  await documentStore.fetchDocuments(props.investor_type, props.investor_id)
}
const signatureSendReminder = async (item) => {
  await documentStore.signatureSendReminder(item.id)
  await documentStore.fetchDocuments(props.investor_type, props.investor_id)
}

const getSigners = (item) => {
  const signers = documentStore.items.get(item.id).signatures.map((signature) => signature.investor.name)
  return signers.join(', ')
}

onMounted(async () => {
  await documentStore.fetchDocuments(props.investor_type, props.investor_id)

  if (can('template', 'view')) {
    await templateStore.fetchTemplates(props.investor_type, props.investor_id)
  }

  internal_skeleton.value = false
})
</script>

<template>
  <div>
    <div class="-mt-7 mb-5 flex justify-end gap-2" v-if="can('template', 'request-signature')">
      <RouterLink :to="requestSignatureUrl">
        <VButton size="md" variant="v-blue"> Request Signatures </VButton>
      </RouterLink>
      <RouterLink :to="buildTemplateUrl">
        <VButton size="md" variant="v-blue"> Build Template </VButton>
      </RouterLink>
    </div>
    <VTable
      :columns="[
        {
          key: 'name',
          name: capitalize(t('shared.template', 0)),
          type: 'string',
          align: 'left',
          fixed: true,
          is_visible: true,
        },
        {
          key: 'usage',
          name: capitalize(t('shared.usage')),
          type: 'number',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'date',
          name: capitalize(t('shared.date')),
          sorted: true,
          type: 'date',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'actions',
          name: '',
          type: 'actions',
          align: 'right',
          is_visible: true,
        },
      ]"
      :items="templates"
      :name="`${props.investor_type}-${props.investor_id}-templates`"
      :skeleton="skeleton"
      :slots="['actions']"
      v-if="can('template', 'view')"
    >
      <template #actions="{ item }">
        <div class="flex gap-1" v-if="can('template', 'action-buttons')">
          <RouterLink :to="generateUseTemplateUrl(item)">
            <VButton size="xs">
              {{ capitalize(t('shared.use')) }}
            </VButton>
          </RouterLink>
          <RouterLink
            :to="{
              name: 'signing-entity-duplicate-template',
              params: {
                templateable_type: props.investor_type,
                templateable_id: props.investor_id,
                template_id: get(item, 'id'),
              },
            }"
          >
            <VButton size="xs">
              {{ capitalize(t('shared.duplicate')) }}
            </VButton>
          </RouterLink>
          <RouterLink
            :to="{
              name: 'signing-entity-edit-template',
              params: {
                templateable_type: props.investor_type,
                templateable_id: props.investor_id,
                template_id: get(item, 'id'),
              },
            }"
          >
            <VButton size="xs">
              {{ capitalize(t('shared.edit')) }}
            </VButton>
          </RouterLink>
          <VButton size="xs" :click="() => removeTemplate(item)">
            {{ capitalize(t('shared.delete')) }}
          </VButton>
        </div>
      </template>
    </VTable>
    <div class="mb-3 mt-10 flex items-center space-x-1.5">
      <VButton :active="selectedDocumentType === 'in-progress'" class="w-36" @click="selectDocumentType('in-progress')">
        <span>{{ capitalize(t('shared.in-progress')) }}</span>
        <span
          class="ml-2 rounded-full px-2 py-0.5 text-xs text-black"
          :class="[selectedDocumentType === 'in-progress' ? 'bg-sky-100' : 'bg-gray-100']"
          >{{ document_count.in_progress }}</span
        >
      </VButton>
      <VButton :active="selectedDocumentType === 'completed'" class="w-36" @click="selectDocumentType('completed')">
        <span>{{ capitalize(t('shared.completed')) }}</span>
        <span
          class="text ml-2 rounded-full px-2 py-0.5 text-xs text-black"
          :class="[selectedDocumentType === 'completed' ? 'bg-sky-100' : 'bg-gray-100']"
          >{{ document_count.completed }}</span
        >
      </VButton>
      <VButton :active="selectedDocumentType === 'voided'" class="w-36" @click="selectDocumentType('voided')">
        <span>{{ capitalize(t('shared.voided')) }}</span>
        <span
          class="text ml-2 rounded-full px-2 py-0.5 text-xs text-black"
          :class="[selectedDocumentType === 'voided' ? 'bg-sky-100' : 'bg-gray-100']"
          >{{ document_count.voided }}</span
        >
      </VButton>
    </div>
    <VTable
      :columns="[
        {
          key: 'name',
          name: capitalize(t('shared.document', 0)),
          type: 'string',
          align: 'left',
          fixed: true,
          is_visible: true,
        },
        {
          key: 'status',
          name: capitalize(t('shared.status')),
          type: 'string',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'date',
          name: capitalize(t('shared.date')),
          sorted: true,
          type: 'date',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'actions',
          name: '',
          type: 'actions',
          align: 'right',
          is_visible: true,
        },
      ]"
      :items="currentDocuments"
      :name="`${props.investor_type}-${props.investor_id}-documents`"
      :skeleton="skeleton"
      :slots="['actions', 'name', 'status']"
      sub_item_key="signatures"
      :expand="true"
    >
      <template #name="{ item }">
        <div class="mb-1 font-medium" v-if="!!item.name">{{ item.name }}</div>
        <div class="text-xs text-gray-500">To: {{ getSigners(item) }}</div>
      </template>
      <template #subgroup.name="{ item }">
        <template v-if="can('investor', 'view')">
          <RouterLink
            v-if="item.investor._custom_type === 'individual'"
            class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
            :to="{ name: 'investing.individual-overview', params: { individual_id: item.investor.id } }"
          >
            {{ item.investor.name }}
          </RouterLink>
          <RouterLink
            v-else
            class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
            :to="{ name: 'investing.other-entity.overview', params: { other_entity_id: item.investor.id } }"
          >
            {{ item.investor.name }}
          </RouterLink>
        </template>
        <template v-else>
          {{ item.investor.name }}
        </template>
      </template>
      <template #status="{ item }"> {{ getDocumentStatus(item) }} </template>
      <template #subgroup.status="{ item }">
        {{ item.status }}
      </template>
      <template #actions="{ item }">
        <div class="flex gap-1">
          <VButton
            size="xs"
            :click="() => voidDocument(item)"
            v-if="item.status === 'awaiting' && can('document', 'void')"
          >
            {{ capitalize(t('shared.void')) }}
          </VButton>
          <VButton size="xs" :click="() => documentStore.downloadDocument(item.id)" v-if="item.status !== 'voided'">
            {{ capitalize(t('shared.download')) }}
          </VButton>
        </div>
      </template>
      <template #subgroup.actions="{ item }">
        <div class="flex justify-end gap-1">
          <VButton
            size="xs"
            :click="() => signatureSendReminder(item)"
            v-if="item.status_code === 'awaiting_signature' && item.status !== 'Signed'"
          >
            {{ capitalize(t('shared.send reminder')) }}
          </VButton>
        </div>
      </template>
    </VTable>
  </div>
</template>
