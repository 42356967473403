<script setup lang="ts">
import { VSection, VTextField, VRadio, VTextArea, VButton, VIcon, VButtonInvisible } from '@/modules/shared/components'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import { not, required } from '@vuelidate/validators'
import { ref, computed, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { CapitalCall } from '../stores/capital-call-store'
import { toNumber, initialMoney, sum, toMoney } from '@/modules/shared/utils/money'
import { useRoute } from 'vue-router'
import { format } from 'date-fns'
import VCurrencyField from '@/modules/shared/components/v-currency-field.vue'
import { transformTransfer, updateTransfer, macrosUpdateTransfer } from '../utils/use-capital-call-form'
import { scrollToError } from '@/modules/shared/utils/form'

const { n, t } = useExtendedI18n()
const route = useRoute()
////////////////////////////////////////
const props = defineProps<{
  capital_call: CapitalCall
  onSave: any
}>()
const is_capital_call_new = computed(() => !props.capital_call.id)
const macros = ref({
  total_capital:
    props.capital_call.selected_capital_type === '$' && props.capital_call.entered_capital_amount
      ? toMoney(props.capital_call.entered_capital_amount, props.capital_call.currency)
      : null,
  total_capital_percent:
    props.capital_call.selected_capital_type === '%' ? props.capital_call.entered_capital_amount : null,
  management_fees: props.capital_call.entered_management_fees_amount,
  other_fees: props.capital_call.entered_other_fees_amount,
})
const selected_capital_type = ref(props.capital_call.selected_capital_type)
const resetMacro = (key) => {
  macros.value[key] = null
  macroUpdateTransfer()
}

const toggleAllInvestor = (checked) => {
  if (!is_capital_call_new.value) return
  capitalCall.value.capital_call_transfers.forEach((transfer) => {
    transfer.is_active = checked
  })
}
const toggleInvestor = (e, transfer) => {
  transfer.is_active = e.target.checked
  if (transfer.is_active) {
    macrosUpdateTransfer(transfer, macros.value, selected_capital_type.value)
  } else {
    transfer.capital = initialMoney
    transfer.management_fees = initialMoney
    transfer.other_fees = initialMoney
    updateTransfer(transfer)
  }
}

// FORM DATA
const transformCapitalCall = () => {
  return {
    ...props.capital_call,
    date: props.capital_call.date ? format(new Date(props.capital_call.date), 'yyyy-MM-dd') : null,
    schedule_date: props.capital_call.schedule_date
      ? format(new Date(props.capital_call.schedule_date), 'yyyy-MM-dd')
      : null,
    capital_call_transfers: props.capital_call.capital_call_transfers
      .map((transfer) => transformTransfer(transfer, props.capital_call))
      .sort((a, b) => a.name.localeCompare(b.name)),
  }
}
const rules = {
  prior: { required },
  date: { required },
}
const capitalCall = ref(transformCapitalCall())
const v$ = useVuelidate(rules, capitalCall, { $lazy: true })
watch(
  () => selected_capital_type.value,
  () => macroUpdateTransfer(),
)
const onChangeTransfer = (transfer) => {
  transfer = updateTransfer(transfer)
}
const macroUpdateTransfer = () => {
  capitalCall.value.capital_call_transfers
    .filter((transfer) => transfer.is_active)
    .forEach((transfer) => {
      transfer = macrosUpdateTransfer(transfer, macros.value, selected_capital_type.value)
    })
}
const aggregateValues = computed(() => {
  const data = {
    capital: initialMoney,
    management_fees: initialMoney,
    other_fees: initialMoney,
    total_called: initialMoney,
    commitment_remaining: initialMoney,
  }
  capitalCall.value.capital_call_transfers.forEach((transfer) => {
    if (transfer.is_active) {
      data.capital = sum([data.capital, transfer.capital || initialMoney])
      data.management_fees = sum([data.management_fees, transfer.management_fees || initialMoney])
      data.other_fees = sum([data.other_fees, transfer.other_fees || initialMoney])
      data.total_called = sum([data.total_called, transfer.total_called])
    }
    data.commitment_remaining = sum([data.commitment_remaining, transfer.commitment_remaining])
  })

  return data
})

const getManagementFee = (transfer) => {
  if (macros.value.management_fees) return macros.value.management_fees / 100
  return transfer.management_fee_percentage || 0
}

const submit = async () => {
  const valid = await v$.value.$validate()
  if (!valid) {
    scrollToError(v$, Object.keys(rules))
    return
  }
  let entered_capital_amount = null
  if (selected_capital_type.value === '$') {
    if (!!macros.value.total_capital) entered_capital_amount = toNumber(macros.value.total_capital)
  } else {
    entered_capital_amount = macros.value.total_capital_percent
  }

  const payload = {
    id: capitalCall.value.id,
    investor_set_id: route.params.entity_id,
    capital_amount: toNumber(aggregateValues.value.capital),
    currency: capitalCall.value.currency,
    date: capitalCall.value.date,
    schedule_date: capitalCall.value.schedule_date,
    notes: capitalCall.value.notes,
    wiring_instructions: capitalCall.value.wiring_instructions,
    prior: `${capitalCall.value.prior}`,
    fee_percentage: 0,
    fee_percentage_of_commitment: 'false',
    include_fees: '1',
    itemize_fee: true,
    other_fee_amount: toNumber(aggregateValues.value.other_fees),
    entered_capital_amount: entered_capital_amount,
    entered_management_fees_amount: macros.value.management_fees,
    entered_other_fees_amount: macros.value.other_fees ? toNumber(macros.value.other_fees) : null,
    selected_capital_type: selected_capital_type.value,
    other_fee_type: 'pro-rata',
    capital_call_transfers_attributes: [],
  }

  // get the active transfers
  const activeTransfers = []
  capitalCall.value.capital_call_transfers
    .filter((transfer) => transfer.is_active)
    .forEach((transfer) => {
      activeTransfers.push({
        id: transfer.id,
        investor_set_commitment_id: transfer.investor_set_commitment_id,
        capital_amount: toNumber(transfer.capital),
        currency: capitalCall.value.currency,
        date: null,
        fee_amount: toNumber(transfer.management_fees),
        other_fee_amount: toNumber(transfer.other_fees),
        prior: false,
      })
    })
  payload.capital_call_transfers_attributes = activeTransfers

  await props.onSave(payload)
}

// TABLE CONFIG
const columns = computed(() => {
  return [
    { key: 'capital', label: 'Capital' },
    { key: 'management_fees', label: 'Management Fees' },
    { key: 'other_fees', label: 'Other Fees' },
    { key: 'total_called', label: 'Total Called', disabled: true },
    { key: 'commitment_remaining', label: 'Commitment Remaining', disabled: true },
  ]
})
</script>

<template>
  <VSection class="flex gap-3" id="general-information">
    <div class="w-1/2">
      <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
        {{ capitalize(t('shared.general information')) }}
        <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">1/2</span>
      </h3>
    </div>
    <div class="w-1/2 space-y-7">
      <VRadio
        v-model="capitalCall.prior"
        :options="[
          { label: 'Generate an invoice and send an email notice to investors.', value: false },
          { label: 'Save without generating an invoice or sending an email notice to investors.', value: true },
        ]"
        optionsDirection="vertical"
        :v$="v$"
        id="capital-call-prior"
        v-if="is_capital_call_new"
      />
      <VTextField
        v-model="capitalCall.date"
        :label="capitalize(t('shared.due date'))"
        type="date"
        property="date"
        :v$="v$"
        :required="true"
        description="When is this capital call due?"
      />
      <VTextField
        v-model="capitalCall.schedule_date"
        :label="capitalize(t('shared.schedule date'))"
        type="date"
        property="schedule_date"
        :v$="v$"
        description="When should this capital call be sent to investors? Leave blank to send immediately."
      />
      <VTextArea
        v-model="capitalCall.notes"
        :label="capitalize(t('shared.note', 0))"
        property="notes"
        :v$="v$"
        description="This section is included in the body of the email sent. Keep it brief. URLs are hyperlinked."
      />
      <VTextArea
        v-model="capitalCall.wiring_instructions"
        :label="capitalize(t('shared.wiring instructions'))"
        property="wiring_instructions"
        :v$="v$"
        description="Wiring instructions are sent encrypted. The investor will need to login to access them. "
      />
    </div>
  </VSection>
  <VSection class="space-y-7" id="investor-allocations">
    <h3 class="inline-flex items-center text-xl text-gray-900 lg:text-xl">
      {{ capitalize(t('shared.investor allocation', 0)) }}
      <span class="ml-3 text-sm font-medium tracking-widest text-gray-400">2/2</span>
    </h3>
    <table id="auto-allocate-table" class="w-full">
      <thead>
        <tr>
          <td class="!align-middle font-semibold">Allocate Automatically</td>
          <td>
            <div class="font-semibold">Total Capital</div>
            <div class="mt-3 text-gray-500">
              Enter a fixed amount or percentage of the fund and the capital to call will be calculated for all
              investors based on their ownership of the fund.
            </div>
            <br />
          </td>
          <td>
            <div class="font-semibold">Total Management Fees</div>
            <div class="mt-3 text-gray-500">
              Enter a percentage and the same percentage will be subtracted from all investors based on their capital to
              call, overriding any investor specific management fees.
            </div>
          </td>
          <td>
            <div class="font-semibold">Total Other Fees</div>
            <div class="mt-3 text-gray-500">
              Enter a fixed amount and the fee will be calculated for all investors based on their ownership of the
              fund.
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <div class="relative h-full gap-3 px-2 py-1">
              <div class="flex items-center">
                <div class="flex h-fit items-center gap-2 text-base">
                  <div
                    class="min-w-[50%]py-0.5"
                    :class="[selected_capital_type === '$' ? 'font-bold' : 'cursor-pointer text-gray-500']"
                    @click="selected_capital_type = '$'"
                  >
                    $
                  </div>
                  <div
                    class="min-w-[50%] py-0.5"
                    :class="[selected_capital_type === '%' ? 'font-bold' : 'cursor-pointer text-gray-500']"
                    @click="selected_capital_type = '%'"
                  >
                    %
                  </div>
                </div>
                <VTextField
                  v-model="macros.total_capital"
                  type="currency"
                  :onInput="macroUpdateTransfer"
                  class="flex-grow"
                  inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right focus:!ring-0"
                  v-if="selected_capital_type === '$'"
                />
                <VTextField
                  v-model="macros.total_capital_percent"
                  type="percent"
                  :onInput="macroUpdateTransfer"
                  class="flex-grow"
                  inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right focus:!ring-0"
                  v-else
                />
              </div>
              <div class="absolute -bottom-3 left-1/2 h-fit w-fit -translate-x-1/2">
                <VButton
                  @click="() => resetMacro(selected_capital_type === '$' ? 'total_capital' : 'total_capital_percent')"
                  size="sm"
                >
                  <VIcon name="reset" class="h-3.5 w-3.5" />
                </VButton>
              </div>
            </div>
          </td>
          <td>
            <div class="relative h-full gap-3 px-2 py-1">
              <VTextField
                v-model="macros.management_fees"
                type="percent"
                :onInput="macroUpdateTransfer"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right focus:!ring-0"
                placeholder="--"
              />
              <div class="absolute -bottom-3 left-1/2 h-fit w-fit -translate-x-1/2">
                <VButton @click="() => resetMacro('management_fees')" size="sm">
                  <VIcon name="reset" class="h-3.5 w-3.5" />
                </VButton>
              </div>
            </div>
          </td>
          <td>
            <div class="relative h-full gap-3 px-2 py-1">
              <VTextField
                v-model="macros.other_fees"
                type="currency"
                :onInput="macroUpdateTransfer"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right focus:!ring-0"
              />
              <div class="absolute -bottom-3 left-1/2 h-fit w-fit -translate-x-1/2">
                <VButton @click="() => resetMacro('other_fees')" size="sm">
                  <VIcon name="reset" class="h-3.5 w-3.5" />
                </VButton>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="relative w-full overflow-x-auto">
      <table id="capital-call-form-table" class="w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th class="pl-3"></th>
            <th class="pl-3">
              <input
                class="inverse bod relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
                type="checkbox"
                @change="toggleAllInvestor($event.target['checked'])"
                checked
                :disabled="!is_capital_call_new"
              />
            </th>
            <th class="pl-3 text-left">Investor</th>
            <th class="pl-3 text-right" v-for="column in columns">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :class="{ disabledRow: !transfer.is_active }" v-for="(transfer, i) in capitalCall.capital_call_transfers">
            <td class="border-l-[1px] border-gray-300 pl-3 text-gray-500">{{ i + 1 }}</td>
            <td class="pl-3">
              <input
                type="checkbox"
                class="inverse bod relative block h-4 w-4 rounded border-gray-300 text-white shadow-sm focus:ring-sky-300 focus:ring-opacity-50"
                @change="(e) => toggleInvestor(e, transfer)"
                :disabled="!is_capital_call_new"
                :checked="transfer.is_active"
              />
            </td>
            <td class="border-r-[1px] border-gray-300 py-2 pl-3 text-base text-gray-700">
              {{ transfer.name }}
            </td>
            <td class="border-r-[1px] border-gray-300 text-base text-gray-700" v-for="column in columns">
              <VTextField
                v-model="transfer[column.key]"
                :disabled="!transfer.is_active || column.disabled"
                type="currency"
                :onInput="() => onChangeTransfer(transfer)"
                inputClass="!border-none !shadow-none !mt-0 text-sm !rounded-none text-right"
                v-if="column.key !== 'management_fees'"
              />
              <div class="flex items-center pl-3" v-else>
                <div class="rounded bg-blue-50 p-1 py-0.5 text-xs text-gray-500">
                  {{ n(getManagementFee(transfer), 'percent') }}
                </div>
                <VTextField
                  v-model="transfer.management_fees"
                  :disabled="!transfer.is_active"
                  type="currency"
                  :onInput="() => onChangeTransfer(transfer)"
                  inputClass="!border-none !shadow-none !mt-0 text-sm text-right"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="border-b-[1px] border-gray-300">
            <td class="border-l-[1px] border-gray-300 pl-3 text-gray-500"></td>
            <td class="pl-3"></td>
            <td class="border-r-[1px] border-gray-300 py-2 pl-3 text-xs text-gray-700">TOTALS</td>
            <td class="border-r-[1px] border-gray-300 bg-gray-50 text-base text-gray-700" v-for="column in columns">
              <VTextField
                v-model="aggregateValues[column.key]"
                :disabled="true"
                type="currency"
                inputClass="!border-none !shadow-none !mt-0 text-sm text-right"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <VButton :click="submit" variant="v-blue" size="custom" class="px-5 py-1">
      {{ capitalize(t('shared.save')) }}
    </VButton>
  </VSection>
</template>

<style>
table#capital-call-form-table {
  @apply text-sm;
}
table#capital-call-form-table thead tr th {
  @apply min-w-[150px] whitespace-nowrap border-b-[1px] border-t-[1px] border-gray-300 px-2 py-2 font-semibold;
}
table#capital-call-form-table thead tr th:first-child {
  @apply border-l-[1px];
}
table#capital-call-form-table thead tr th:last-child {
  @apply border-r-[1px];
}
table#capital-call-form-table tbody tr td {
  @apply border-b-[1px] border-gray-300;
}
table#capital-call-form-table tfoot tr td {
  @apply border-b-[1px] border-gray-300;
}
table#capital-call-form-table thead tr th:nth-child(1),
table#capital-call-form-table thead tr th:nth-child(2) {
  @apply w-[1%] !min-w-[1%];
}
table#capital-call-form-table thead tr th:nth-child(1),
table#capital-call-form-table tbody tr td:nth-child(1),
table#capital-call-form-table tfoot tr td:nth-child(1) {
  @apply sticky left-0 bg-white;
}
table#capital-call-form-table thead tr th:nth-child(2),
table#capital-call-form-table tbody tr td:nth-child(2),
table#capital-call-form-table tfoot tr td:nth-child(2) {
  @apply sticky left-[22px] bg-white;
}
table#capital-call-form-table thead tr th:nth-child(3),
table#capital-call-form-table tbody tr td:nth-child(3),
table#capital-call-form-table tfoot tr td:nth-child(3) {
  @apply sticky left-[51px] border-r-[1px] bg-white;
}
table#capital-call-form-table thead tr th:nth-child(3) {
  @apply !min-w-[300px];
}

table#capital-call-form-table tbody tr.disabledRow td {
  @apply bg-gray-100;
}
table#auto-allocate-table {
  @apply text-sm;
}
table#auto-allocate-table thead tr td {
  @apply min-w-[150px] max-w-[300px] border-[1px] border-gray-300 p-2 align-top;
}
table#auto-allocate-table tbody tr td {
  @apply border-[1px] border-gray-300;
}

#capital-call-prior .radio-container label:nth-child(1) {
  @apply rounded-t-lg border-[1px];
}

#capital-call-prior .radio-container label:nth-child(2) {
  @apply rounded-b-lg  border-b-[1px]  border-l-[1px]  border-r-[1px];
}

#capital-call-prior .radio-container label {
  @apply !m-0 cursor-pointer border-gray-300 px-5 py-3 hover:border-sky-500 hover:bg-sky-100;
}

#capital-call-prior .radio-container:hover label:nth-child(1) {
  @apply border-b-sky-500;
}
</style>
